import React, { useState, useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import moment from "moment";
import QRCode from "qrcode.react";

import { Button } from "gatsby-material-ui-components";

import CardContent from "@material-ui/core/CardContent";
import Container from "../components/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import ShareIcon from "@material-ui/icons/Share";

import Layout from "../components/layout";
import MoreButton from "../components/MoreButton";
import BackButton from "../components/BackButton";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";
import i18nContext from "../i18n-context";

import { convertSlashDateToMoment, formatMomentDateByLang } from "../utils";

const BookContentPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const theme = useTheme();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [shareOptionsAnchorEl, setShareOptionsAnchorEl] = useState(null);

  const {
    title,
    date,
    content: contentHTML,
    translated,
    contentGenericGroup: {
      author,
      synopsis,
      publicationDate,
      thumbnail,
      additionalTags,
      contentDetailPageBanner,
    } = {
      author: "",
      synopsis: "",
      publicationDate: "",
      thumbnail: "",
      contentDetailPageBanner: {},
    },
  } = pageContext;
  const bannerSrcUrl = (contentDetailPageBanner || thumbnail)?.sourceUrl;
  const tags = additionalTags?.split(", ") || [];
  const urlLang = lang === "tc" ? "/zh-hant" : lang === "sc" ? "/zh-hans" : ""

  const {
    bookPage,
    wpBook: {
      booksGroup: { bookLanguage, purchaseLink },
    },
  } = data;
  let backButtonUrl = bookPage.uri;
  let backButtonName = bookPage.title;

  if (lang === "en") moment.locale("en");
  if (lang === "tc") moment.locale("zh-tw");
  if (lang === "sc") moment.locale("zh-cn");

  const postDate = moment()
    .set(new Date(date))
    .format(lang === "en" ? "D MMMM YYYY" : "LL");

  const pubDate = formatMomentDateByLang(
    convertSlashDateToMoment(publicationDate),
    lang
  );

  const shareButtonsConfig = [
    {
      href: `https://service.weibo.com/share/share.php?url=${url}&title=${title}`,
      src: "/weibo.png",
      alt: "share-button-weibo",
    },
    {
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
      src: "/linkedin.png",
      alt: "share-button-linkedin",
    },
    {
      href: `https://twitter.com/share?text=${title}&url=${url}`,
      src: "/twitter.png",
      alt: "share-button-twitter",
    },
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      src: "/facebook.png",
      alt: "share-button-facebook",
    },
    {
      src: "/wechat.png",
      alt: "share-button-wechat",
      onClick: () => setDialogOpen(true),
    },
  ];

  const toggleShareOptions = (event) => {
    setShareOptionsAnchorEl(event.currentTarget);
  };

  const handleShareOptionsClose = () => {
    setShareOptionsAnchorEl(null);
  };

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={synopsis} />
        <meta property="og:image" content={bannerSrcUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="og:type" content="article" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={synopsis} />
        <meta name="twitter:image" content={bannerSrcUrl} />
      </Helmet>

      <Container className={classes.container}>
        <div className={classes.headingSection} aria-label="content-headings">
          <Typography variant="body2" className={classes.headingCategory}>
            {bookPage.title}
          </Typography>
          <Typography variant="h4" paragraph>
            {title}
          </Typography>
          {/* <Typography variant="body2" paragraph>
            {postDate}
          </Typography> */}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={12} >
                <img className={classes.imageWidth} src={bannerSrcUrl} alt="" />
              </Grid>
              <Grid item xs={6} md={12}>
                <div className={classes.textArea}>
                  {author && (
                    <div>
                      <Typography
                        variant="body2"
                        className={classes.boldFont}
                      >{`${i18n.contentPage.author}`}</Typography>
                      <Typography
                        paragraph
                        variant="body2"
                      >{`${author}`}</Typography>
                    </div>
                  )}
                  {pubDate && (
                    <div>
                      <Typography
                        variant="body2"
                        className={classes.boldFont}
                      >{`${i18n.contentPage.pubDate}`}</Typography>
                      <Typography
                        variant="body2"
                        paragraph
                      >{`${pubDate}`}</Typography>
                    </div>
                  )}
                  {bookLanguage && (
                    <div>
                      <Typography
                        variant="body2"
                        className={classes.boldFont}
                      >{`${i18n.contentPage.language}`}</Typography>
                      <Typography
                        variant="body2"
                        paragraph
                      >{`${bookLanguage}`}</Typography>
                    </div>
                  )}
                </div>
                {purchaseLink && (
                  <div>
                    <MoreButton
                      href={purchaseLink}
                      color={theme.palette.error.main}
                      textColor={theme.palette.primary.main}
                      target="_blank"
                    >
                      {i18n.contentPage.purchase}
                    </MoreButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={9}>
            <Typography
              paragraph
              variant="body2"
              component="div"
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: contentHTML }}
            />
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container spacing={2} className={classes.actionButtons}>
          <Grid item>
            <Button startIcon={<ShareIcon />} onClick={toggleShareOptions}>
              {i18n.contentPage.share}
            </Button>
            <Popover
              anchorEl={shareOptionsAnchorEl}
              open={Boolean(shareOptionsAnchorEl)}
              onClose={handleShareOptionsClose}
              elevation={0}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Paper variant="outlined" elevation={0} square>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body2">
                    {i18n.contentPage.selectSharingChannel}
                  </Typography>
                  <Grid container spacing={1}>
                    {shareButtonsConfig.map((button) => (
                      <Grid item key={button.alt}>
                        <IconButton
                          href={button.href}
                          onClick={button.onClick}
                          target="_blank"
                        >
                          <img
                            src={button.src}
                            alt={button.alt}
                            style={{
                              objectFit: "fill",
                              width: "32px",
                              height: "auto",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Paper>
            </Popover>
          </Grid>
        </Grid>

        {Array.isArray(tags) && tags.length > 0 && (
          <div>
            <Typography variant="body2" className={classes.articleTagsHeading}>
              {i18n.contentPage.articleTags}
            </Typography>
            <Grid container spacing={1}>
              {tags.map((tag) => (
                <Grid item key={`${title}-tag-${tag}`}>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.articleTag}
		    to={`${urlLang}/search?search=${encodeURIComponent(tag)}`}
                  >
                    {tag}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Divider className={classes.divider} />
          </div>
        )}

        <BackButton
          to={backButtonUrl}
        >{`${i18n.contentPage.backTo}${backButtonName}`}</BackButton>
      </Container>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <QRCode value={url} />
          </Grid>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginBottom: theme.spacing(4),
  },
  headingSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headingCategory: {
    color: theme.palette.error.main,
  },
  thumbnail: {
    marginBottom: theme.spacing(4),
    maxWidth: "100%",
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      maxWidth: `calc(100% + 32px)`,
    },
  },
  content: {
    "& p": {
      margin: 0,
      paddingBottom: "1.125rem"
    },
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  actionButtons: {
    marginBottom: theme.spacing(2),
  },
  articleTagsHeading: {
    marginBottom: theme.spacing(2),
  },
  articleTag: {
    borderRadius: 0,
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  backButtonContainer: {
    marginBottom: theme.spacing(4),
  },
  backButton: {
    fontWeight: 400,
  },
  cardContent: {
    paddingBottom: `16px !important`,
  },
  boldFont: {
    fontWeight: "800 !important",
  },
  imageWidth: {
    width: "100%"
  },
  textArea: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 16
    }
  }
}));

export const query = graphql`
  query getBookPostQuery($id: String, $locale: String) {
    bookPage: wpPage(
      pageType: { pageType: { eq: "9" } }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      title
    }
    wpBook(id: { eq: $id }) {
      booksGroup {
        bookLanguage
        purchaseLink
      }
      researchCategories {
        nodes {
          name
        }
      }
    }
  }
`;

export default BookContentPage;
